

.imageBG {

  background: url("./bg1.jpg");
  background-repeat: no-repeat !important;
  /* background-size: 100vw 150vh !important; */
  background-size:cover;
  text-shadow: -1px 1px 5px #fff;
}

body {
  /* background: rgb(34,193,195);
  background: url("./bg1.jpg"), linear-gradient(30deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%) !important; */
  /* background: #787CF9 !important; */
  background-color: #8083EE;
  /* background-image: url("./bg1.jpg"); */
  
}

section {
  
  /* background: linear-gradient(180deg, #787CF9 0%, rgb(107, 166, 233) 100%); */
  background: linear-gradient(270deg, #787CF9 0%, #8083F8 100%);
}